import React from 'react';
import { Grid, Section, SmolBlock } from 'src/components';
import { $t } from 'src/utils';

const HowItWorksSection = () => {
  return (
    <Section
      title={$t({
        id: 'section.how-it-works-title',
        defaultMessage: 'Get coverage fast and on your schedule.',
      })}
      bg="var(--g-red-light)"
    >
      <Grid num={3}>
        <SmolBlock
          icon="/iconic/clock.svg"
          alt="clock icon"
          title={$t({
            id: 'section.how-it-works-item-1-title',
            defaultMessage: '10 minutes.',
          })}
          text={$t({
            id: 'section.how-it-works-item-1-description',
            defaultMessage: 'Get covered in less time than it takes to brew a cup of coffee',
          })}
        />
        <SmolBlock
          icon="/iconic/annotation.svg"
          alt="support icon"
          title={$t({
            id: 'section.how-it-works-item-2-title',
            defaultMessage: 'Live support.',
          })}
          text={$t({
            id: 'section.how-it-works-item-2-description',
            defaultMessage:
              'Want to talk to a human? Our experts are here to help every step of the way',
          })}
        />
        <SmolBlock
          icon="/iconic/target.svg"
          alt="pin icon"
          title={$t({
            id: 'section.how-it-works-item-3-title',
            defaultMessage: 'No guesswork.',
          })}
          text={$t({
            id: 'section.how-it-works-item-3-description',
            defaultMessage: 'Pick from plans recommended for your specific needs',
          })}
        />
      </Grid>
    </Section>
  );
};

export default HowItWorksSection;
