import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Section, BPost, Grid } from 'src/components';

const COLORS = ['#00E1AB', '#4A80FF', '#FF7900'];

const QUERY = gql`
  query Posts($verticals: [Vertical!]) {
    posts(
      first: 3
      orderBy: postDate_DESC
      where: { displayComponent: true, vertical_in: $verticals }
    ) {
      id
      title
      slug
      vertical
      externalUrl
      postDate
      image {
        id
        url(transformation: { image: { resize: { width: 500, height: 400 } } })
      }
    }
  }
`;

/**
 * Section for showing a preview of 3 posts
 */
const PostSection = ({ title = <>Personal Payroll and Benefits&nbsp;Know-How</>, vertical }) => {
  const data = useQuery(QUERY, {
    variables: {
      verticals: !!vertical ? [vertical] : ['health', 'retirement', 'tax', 'time_off'],
    },
  });
  const posts = data?.data?.posts;

  return (
    <Section title={title} pretitle="Guides and Resources">
      <br />
      <Grid>
        {posts?.map((post, idx) => (
          <BPost
            key={post?.slug}
            color={COLORS[idx]}
            date={post.postDate}
            link={post.externalUrl || '/post/' + post.slug}
            image={post?.image?.url}
            title={post.title}
          />
        ))}
      </Grid>
    </Section>
  );
};

export default PostSection;
