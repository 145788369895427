import React from 'react';
import { Button, SplitHero } from 'src/components';
import paths from 'src/constants/paths';
import { $t } from 'src/utils';

const BenefitsAdvisorSection = () => {
  return (
    <SplitHero
      title={$t({
        id: 'section.benefits-advisor-title',
        defaultMessage: 'Want help? Meet your Catch expert.',
      })}
      subtitle={$t({
        id: 'section.benefits-advisor-subtitle',
        defaultMessage:
          'Our team is ready to support every step of the way. We’ll help you find the insurance plans that fit you best, at no cost to you.',
      })}
      accessory={
        <img src="/graphics/coverage_stack.png" height={600} alt="Stack of health plan options" />
      }
    >
      <Button large to={paths.CONTACT}>
        {$t({ id: 'section.benefits-advisor.btn', defaultMessage: 'Get in touch' })}
      </Button>
    </SplitHero>
  );
};

export default BenefitsAdvisorSection;
