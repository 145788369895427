import React from 'react';
import { Section, Block } from 'src/components';

const NewSection = ({ title, text, bg, ...rest }) => {
  return (
    <Section small bg={bg} {...rest}>
      <Block pretitle="New" title={title} text={text} />
      <br />
      <br />
      <br />
      <br />
      <br />
    </Section>
  );
};

export default NewSection;
