import React from 'react';
import { Section, Split, AppGraphic, Block } from 'src/components';

/**
 *
 * @deprecated
 */
const FeaturesSection = ({}) => {
  return (
    <Section small>
      <Split stripe="peach">
        <AppGraphic alt="A creative sitting atop a mountain" image="/photos/mountain.webp" />
        <Block
          title="Smart paycheck withholding"
          text="Each time you’re paid, Catch sets a percentage aside for your benefits. Approve each transfer yourself, or put your plan on Autopilot."
        />
      </Split>
      <Split reverse stripe="algae">
        <AppGraphic alt="A woman relaxing" image="/photos/relax.webp" y="bottom" x="left" />
        <Block
          title="Adjust as you go"
          text="Add new benefits, change your withholding rate, or transfer funds back to your bank in seconds."
        />
      </Split>
      <Split stripe="quartz">
        <Block
          alt="A mom moving into a new house"
          title="Expert advice"
          text="Whether it’s a marriage, a move, or a slow season, we’ve got your back with personalized recommendations no matter what happens."
        />
      </Split>
    </Section>
  );
};

export default FeaturesSection;
