import React from 'react';
import { Section, Quote, Grid } from 'src/components';
import { $t } from 'src/utils';

/**
 *
 */
const QuotesSection = (props) => {
  return (
    <Section
      title={$t({
        id: 'section.quotes-title',
        defaultMessage: 'Trusted by people like you.',
      })}
      narrow
      centerTitle
      {...props}
    >
      <Grid num={2}>
        <Quote
          name={$t({
            id: 'section.quote-1-name',
            defaultMessage: 'Milton',
          })}
          title={$t({
            id: 'section.quote-1-description',
            defaultMessage: 'Tasker with Hyer',
          })}
          body={$t({
            id: 'section.quote-1-body',
            defaultMessage:
              "Sarah made sure I was getting all the savings I was eligible for on health insurance. I can't thank you enough. You saved me a lot of money.",
          })}
        />
        <Quote
          name={$t({
            id: 'section.quote-2-name',
            defaultMessage: 'Calvin',
          })}
          title={$t({
            id: 'section.quote-2-description',
            defaultMessage: 'Self-employed',
          })}
          body={$t({
            id: 'section.quote-2-body',
            defaultMessage:
              'Catch helped me save about $650 per month on my health insurance. If you buy your own insurance, you have to check it out.',
          })}
        />
      </Grid>
    </Section>
  );
};

export default QuotesSection;
