import React from 'react';

import { Section } from 'src/components';

const OneAppSection = ({
  title = 'Build your safety net the simple way.',
  children,
}: {
  title?: string;
  children?: React.ReactNode;
}) => {
  return (
    <div
      style={{
        background: 'var(--c-cerise-0) url(/mesh/cerise.jpg) center/cover no-repeat',
        textAlign: 'center',
      }}
    >
      <Section bg="transparent" dark>
        <h2
          style={{
            fontWeight: 500,
            WebkitMask:
              'linear-gradient(to bottom, #000000 0, #00000066 200px, #00000033 250px, #00000000 400px)',
            height: 430,
            maxWidth: 1000,
            margin: 'auto',
            overflow: 'hidden',
            display: 'block',
            color: 'white',
          }}
        >
          Freelancers, founders, therapists, nannies, photographers, writers, consultants, retirees,
          gig workers, investors, influencers, creatives, musicians, producers, videographers,
          florists, artists, journalists, designers, accountants, trainers, drivers, independent
          workers, lawyers, construction workers, marketers, solopreneurs,
        </h2>

        <h1
          style={{
            marginTop: -64,
            display: 'block',
            fontWeight: 600,
            marginBottom: 64,
            color: 'white',
          }}
        >
          {title}
        </h1>
        {children}
      </Section>
    </div>
  );
};

export default OneAppSection;
