import React, { useContext } from 'react';
import SessionContext from 'src/context/SessionContext';
import { Section, Button } from 'src/components';

/**
 * BenefitsSection
 */
const BenefitsSection = ({
  pretitle,
  title = 'Manage it all, all from one place',
  subtitle = 'Whether you work for yourself or have a few side gigs, you still need tax withholding, time off, retirement, and health insurance. Catch gets you squared away, once and for all.',
  ...rest
}) => {
  const { signupUrl } = useContext(SessionContext);
  return (
    <>
      <Section
        large
        pretitle={pretitle}
        title={title}
        subtitle={subtitle}
        id="benefits"
        top
        nav
        {...rest}
      >
        <Button
          large
          purpose="Sign up"
          onPress={() => {
            window.location = signupUrl;
          }}
        >
          Get Started
        </Button>
      </Section>
    </>
  );
};

export default BenefitsSection;
